import * as client from '../../adapters/client';
import { requestCodeMeta, notifyAnalytics, addCartDataLayer } from '../../adapters/analytics-utils';
import {
    FETCH_OFFER_ERROR,
    FETCH_OFFER_SUCCESS,
    CREATE_SHOPPING_CART_SUCCESS,
    CREATE_SHOPPING_CART_ERROR,
    UPDATE_SHOPPING_CART_SUCCESS,
    UPDATE_SHOPPING_CART_ERROR,
    UPDATE_SELECTED_OFFER_DATA,
    UPDATE_SHOPPING_CART_INIT,
    SHOW_SKIP_RECHARGE_MODAL,
    RESET_OFFER
} from '../actions';
import * as errorCodeMeta from '../../adapters/errorCode';
import { isEmpty } from '../../adapters/validations';
import store from '../../store';
import { nextPage } from '../../adapters/utils';
import { updateStepperCustomData } from '../../components/Progress/module';
import { setAutoRecharge } from '../payment/module';

// Reducer
const initialState = {
    loading: { isOffersLoaded: false, updateCartInProgress: false },
    customerOffers: [],
    selectedOffer: {},
    errors: { hasError: false, errorCode: null, hasServerError: false },
    showSkipRechargeModal: false
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case FETCH_OFFER_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, isOffersLoaded: true },
                customerOffers: action.offers
            };
        case FETCH_OFFER_ERROR:
            return {
                ...state,
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode, hasServerError: action.errorCode === errorCodeMeta.SERVER_ERROR },
                loading: { ...state.loading, isOffersLoaded: true }
            };
        case RESET_OFFER:
            return {
                ...state,
                loading: { ...state.loading, isOffersLoaded: false },
                customerOffers: [],
                errors: { hasError: false, errorCode: null, hasServerError: false }
            };
        case CREATE_SHOPPING_CART_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, hasProcessedCustomerCheck: true, cartCreated: true }
            };

        case CREATE_SHOPPING_CART_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hasProcessedCustomerCheck: false,
                    lockFields: action.errorCode === errorCodeMeta.SERVER_ERROR || action.errorCode === errorCodeMeta.BAD_REQUEST || action.errorCode === errorCodeMeta.MULTIPLE_CUSTOMERS_FOUND
                },
                app: { ...state.app, hasErrorOccurred: true },
                appData: { ...state.appData, uiState: { appLock: true } },
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode, hasServerError: true }
            };

        case UPDATE_SELECTED_OFFER_DATA:
            return {
                ...state,
                selectedOffer: action.selectedOffer
            };
        case UPDATE_SHOPPING_CART_INIT:
            return {
                ...state,
                loading: { ...state.loading, updateCartInProgress: true }
            };
        case UPDATE_SHOPPING_CART_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hasProcessedCustomerCheck: false,
                    updateCartInProgress: false
                },
                appData: { ...state.appData, uiState: { appLock: true } },
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode, hasServerError: true }
            };
        case UPDATE_SHOPPING_CART_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, hasProcessedCustomerCheck: true, cartUpdated: true, updateCartInProgress: false }
            };
        case SHOW_SKIP_RECHARGE_MODAL:
            return {
                ...state,
                showSkipRechargeModal: action.data
            };

        default:
            return state;
    }
};
export default reducer;

// Action Creators

/**
 * makes a service call to fetch the applicable
 * offer based on customer selected device.
 *
 * @param {any} [dataFactory=client.fetchOffers]
 * @returns
 */
export const loadOffers = (dataFactory = client.fetchOffers) => {
    return (dispatch) => {
        dataFactory(dispatch, offersLoaded, offersLoadingError);
    };
};

/**
 * Dispatch success object for service call
 *
 * @param {any} offers
 * @returns
 */
export const offersLoaded = (offers) => {
    return { type: FETCH_OFFER_SUCCESS, offers: offers.data };
};
/**
 * Dispatch error object of service call
 *
 * @param {any} httpStatus
 * @returns
 */
export const offersLoadingError = (httpStatus) => {
    notifyAnalytics(requestCodeMeta.offersFailure, errorCodeMeta.NO_OFFERS);

    return { type: FETCH_OFFER_ERROR, httpStatus };
};

export const createShoppingCart = ({ customerAccountUUID, portingFlow }, fetcher = client.createShoppingCartData) => {
    const params = { data: { customerAccountUUID: `${customerAccountUUID}`, portingFlow } };
    const options = { ...params };
    return (dispatch) => {
        return fetcher(dispatch, shoppingCartCreationSuccess, shoppingCartCreationError, options);
    };
};

export const shoppingCartCreationSuccess = (cartData) => {
    const { hasError, errorCode, data } = processShoppingCartData(cartData);
    if (hasError) {
        return shoppingCartCreationError(errorCode);
    }

    // load offers if cart creation is success
    const state = store.getState();
    const {
        appData: { uiState: { selectedDevice = '' } = {}, serviceNumber: { packageData: { deviceOffers = [] } } = {} }
    } = state.app || {};
    const searchDevice = !isEmpty(selectedDevice) ? selectedDevice.replace(/\s+/g, '-') : 'Prepaid-Mobile-3G-Handset';

    const deviceData = deviceOffers.filter((device) => device.deviceValue === searchDevice)[0];

    return (dispatch) => {
        dispatch({
            type: CREATE_SHOPPING_CART_SUCCESS,
            data
        });

        dispatch({
            type: FETCH_OFFER_SUCCESS,
            offers: deviceData.offers
        });
    };
};

export const shoppingCartCreationError = (httpStatus) => {
    const errorCode = processServerError(httpStatus);

    return { type: CREATE_SHOPPING_CART_ERROR, errorCode };
};

const processShoppingCartData = (cartData) => {
    let errorCode = '';
    if (!cartData.data || (cartData.data && !cartData.data.id)) {
        errorCode = errorCodeMeta.SHOPPING_CART_CREATION_ERROR;
    }
    if (cartData.status.toLowerCase() !== 'success') {
        const updateCartStatus = cartData.status.toString();
        if (updateCartStatus.indexOf('-FATAL-')) {
            errorCode = errorCodeMeta.CREATE_CART_FATAL_ERROR;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: cartData.data };
};

const processShoppingCartUpdateData = (cartData) => {
    let errorCode = '';
    if (cartData.status.toLowerCase() !== 'success') {
        const updateCartStatus = cartData.status.toString();
        if (updateCartStatus === 'RTFA-DECLINE') {
            errorCode = errorCodeMeta.FRAUD_DECLINED_ERROR;
        } else if (updateCartStatus.startsWith('FRAUD-FATAL')) {
            errorCode = errorCodeMeta.UPDATE_CART_FATAL_ERROR;
        } else if (updateCartStatus.startsWith('DXPCAO-FATAL')) {
            errorCode = errorCodeMeta.UPDATE_CART_FATAL_ERROR;
        } else if (updateCartStatus.startsWith('DXPCRO-FATAL')) {
            errorCode = errorCodeMeta.UPDATE_CART_FATAL_ERROR;
        } else {
            errorCode = errorCodeMeta.UPDATE_CART_FATAL_ERROR;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: cartData.data };
};

const processServerError = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 400:
            errorCode = errorCodeMeta.BAD_REQUEST;
            break;
        case 404:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 405:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 4000:
            errorCode = errorCodeMeta.NETWORK_FAILURE;
            break;
        default:
            errorCode = httpStatus;
            break;
    }

    return errorCode;
};

export const updateCart = (offerDetails, skipRecharge, dataFactory = client.updateCartData) => {
    const { productOfferingId, rechargeId, starterCredit, shoppingCartId } = offerDetails;

    const payload = {
        data: {
            productOfferingId,
            rechargeId,
            starterCredit,
            shoppingCartId
        }
    };

    return (dispatch) => {
        dispatch({
            type: UPDATE_SHOPPING_CART_INIT,
            skipRecharge
        });
        dataFactory(dispatch, shoppingCartUpdateSuccess, shoppingCartUpdateFailure, payload);
    };
};

export const shoppingCartUpdateSuccess = (cartData) => {
    const { hasError, errorCode, data } = processShoppingCartUpdateData(cartData);

    if (hasError) {
        return shoppingCartUpdateFailure(errorCode);
    }

    return (dispatch) => {
        dispatch({
            type: UPDATE_SHOPPING_CART_SUCCESS,
            data
        });
        dispatch(setAutoRecharge(data.enableAutoRechargeByDefault));
        const { app, offerDetails } = store.getState();
        const productOfferingId = offerDetails.selectedOffer.productOfferingId;
        const productOfferingName = offerDetails.selectedOffer.productOfferingName;
        const planValue = offerDetails.selectedOffer.price?.replace?.('$','');
        const amount = cartData.data.cartTotalPrice[0].price.dutyFreeAmount.value;
        const { starterCredit } = app.appData.serviceNumber;
        const productData = {
            productID: productOfferingId,
            productName: productOfferingName,
            planValue,
            price: parseInt(amount, 10),
            quantity: 1,
            starterCredit
        };
        addCartDataLayer(productData);
        /** calling and Dispatching updateStepperCustomData from ProgressBar to Manualy Update
           Stepperbar * */
        nextPage(updateStepperCustomData);
        return null;
    };
};

export const shoppingCartUpdateFailure = (httpStatus) => {
    const errorCode = processServerError(httpStatus);
    return { type: UPDATE_SHOPPING_CART_ERROR, errorCode };
};

export const updateSelectedOffer = (selectedOffer) => {
    return {
        type: UPDATE_SELECTED_OFFER_DATA,
        selectedOffer
    };
};

export function triggerSkipRechargeModal(data) {
    return { type: SHOW_SKIP_RECHARGE_MODAL, data };
};

export const resetOffer = () => {
    return {
        type: RESET_OFFER
    };
};
